<!-- 保养日历 -->
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>保养日历</span>
            </div>
            <el-calendar v-model="value" class=" pdl30 pdr30">
                <template slot="dateCell" slot-scope="{date,data}">
                    <!-- <div @click="calClick(date,data)">
                        <div class="dayItem" v-if="data.day.substr(-2) < 10" style="flex:1;text-align: right;">
                            {{ data.day.substr(-1)}}
                        </div>
                        <p class="dayItem" v-else style="flex:1;text-align: right;">{{ data.day.substr(-2)}}</p>
                    </div> -->
                    <!-- <div> -->
                    <!-- 日 -->
                    <div>
                        <div class="calendar-day" style="text-align: center">
                            <div v-if="brightDate.indexOf(data.day) != -1" class="flex flex_column">
                                <span class="everyDay">{{ data.day.split('-').slice(2).join('-') }}</span>
                                <span style="text-align: right">计划：{{content(data.day)}}</span>
                            </div>
                            <!-- <el-tooltip v-if="brightDate.indexOf(data.day) != -1" class="item" effect="dark"
                                :content="content(data.day)" placement="right">
                                <span class="everyDay">{{ data.day.split('-').slice(2).join('-') }}</span>
                            </el-tooltip> -->
                            <span v-else>{{ data.day.split('-').slice(2).join('-') }}</span>

                            <div v-if="brightDate2.indexOf(data.day) != -1" class="flex flex_column">
                                <span style="text-align: right">工单：{{content2(data.day)}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                </template>
            </el-calendar>
            <!-- <div class="flex justify_center">
                <div class="dateDiv bgc_03CA6F mg_right_22 flex justify_center align_center">进行中</div>
                <div class="dateDiv bgc_FD8006 flex justify_center align_center">未开始</div>
            </div> -->
        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        maintainCalendarQuery
    } from "@/api/api_deviceManagement";
    export default {

        data() {
            return {
                arr: [],
                value: new Date(),
                selectDate: [
                    '2021-03-29'
                ],
                selectDate2: [
                    '2021-04-29'
                ],
                planList: [],
                orderList: [],
                calendarData1: [{
                    months: ['06', '11'],
                    days: ['15', '16'],
                    things: '看电影'
                }],
                userID:''
            }
        },
        computed: {
            // 时间高亮的数组
            brightDate() {
                let ary = []
                for (let i in this.planList) {
                    ary.push(this.tools.formatDate(this.planList[i].planTime, 'yyyy-MM-dd'))
                }
                // console.log(ary)
                return ary
            },
            brightDate2() {
                let ary = []
                for (let i in this.orderList) {
                    ary.push(this.tools.formatDate(this.orderList[i].startTime, 'yyyy-MM-dd'))
                }
                // console.log(ary)
                return ary
            }
        },
        created: function () {
            this.$nextTick(() => {
                // 点击前一个月
                let prevBtn = document.querySelector(
                    ".el-calendar__button-group .el-button-group>button:nth-child(1)"
                );
                prevBtn.addEventListener("click", e => {
                    console.log(this.value);
                    console.log("上一月")
                    // this.$notify({
                    //     title: "上一月",
                    //     message: "上个月头天：" + this.value,
                    //     type: "success",
                    //     position: "top-left"
                    // });
                    this.maintainCalendarQuery();
                });

                //点击下一个月
                let nextBtn = document.querySelector(
                    ".el-calendar__button-group .el-button-group>button:nth-child(3)"
                );
                nextBtn.addEventListener("click", () => {
                    console.log(this.value);
                    console.log("下一月")
                    // this.$notify({
                    //     title: "下一月",
                    //     message: "下个月头天：" + this.value,
                    //     type: "warning",
                    //     position: "top-left"
                    // });
                    this.maintainCalendarQuery();
                });

                //点击今天
                let todayBtn = document.querySelector(
                    ".el-calendar__button-group .el-button-group>button:nth-child(2)"
                );
                todayBtn.addEventListener("click", () => {
                    console.log("今天")
                    // this.$notify.info({
                    //     title: "今天",
                    //     message: "今天：" + this.value,
                    //     position: "top-left"
                    // });
                    this.maintainCalendarQuery();
                });
            });

        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID;
            }
            this.maintainCalendarQuery();
        },
        methods: {
            // Tooltip 文字提示
            content(date) {
                // console.log(date)
                let content = '';
                let aaa = [];
                for (let i in this.planList) {
                    if (date === this.tools.formatDate(this.planList[i].planTime, 'yyyy-MM-dd')) {
                        // content = this.calendarData[i].judgeName + ` ` + this.calendarData[i].tribunalName
                        // content = i
                        aaa.push(i)
                    }
                }
                content = aaa.length
                // console.log(aaa.length)
                return content
            },
            content2(date) {
                // console.log(date)
                let content = '';
                let aaa = [];
                for (let i in this.orderList) {
                    if (date === this.tools.formatDate(this.orderList[i].startTime, 'yyyy-MM-dd')) {
                        // content = this.calendarData[i].judgeName + ` ` + this.calendarData[i].tribunalName
                        aaa.push(i)
                    }
                }
                content = aaa.length
                return content
            },
            async maintainCalendarQuery() {

                this.value.setDate(1);
                this.value.setHours(0);
                this.value.setSeconds(0);
                this.value.setMinutes(0);
                var param = {
                    userID:this.userID,
                    startMonth: this.tools.formatDate(this.value, 'yyyy-MM-ddThh:mm:ss'),
                }
                const selectRes = await maintainCalendarQuery(param);
                this.planList = selectRes.plan.rows;
                this.orderList = selectRes.order.rows;
            },
            calClick(date, item) {
                console.log(date.getTime())
                console.log(item)
                // this.formData.data = item.day
            },
            showButton(e) {
                // console.log(this.selectDate.indexOf(e));
                return this.selectDate.indexOf(e) >= 0;
            },
            showButton2(e) {
                // console.log(this.selectDate2.indexOf(e));
                return this.selectDate2.indexOf(e) >= 0;
            }

        },
    }
</script>

<style scoped>
    .dateDiv {
        width: 120px;
        height: 36px;
    }

    .dateButton {
        width: 230px;
        height: 48px;
    }
</style>
<style scoped lang="less">


</style>